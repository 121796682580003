import { useMemo, useRef } from "react";

import ElementsContext from "contexts/elements";
import isNullOrUndefined from "utils/typeChecks/isNullOrUndefined";

const ElementsProvide = ({ children }) => {
	const elementsStoreRef = useRef({});

	const providerValue = useMemo(() => {
		const setElementToStore = (name, element) => {
			if (isNullOrUndefined(element)) {
				console.error(`{${name}} element doesn't exist please check`);
				return;
			}

			elementsStoreRef.current[name] = element;
		};

		const removeElementFromStore = (name) => {
			delete elementsStoreRef.current[name];
		};

		const getElementFromStore = (name) => {
			const element = elementsStoreRef.current[name];
			if (!element) {
				console.error(
					`Element "${name}" dose not exist in store. Available elements {${Object.keys(elementsStoreRef.current)}}`
				);
				return null;
			}

			return element;
		};

		return {
			setElementToStore,
			removeElementFromStore,
			getElementFromStore
		};
	}, []);

	return <ElementsContext.Provider value={providerValue}>{children}</ElementsContext.Provider>;
};

export default ElementsProvide;
