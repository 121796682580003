import isNull from "./isNull";
import isUndefined from "./isUndefined";

/**
 * Check argument is null or undefined
 * @function
 * @param {any} arg possibly null or undefined
 * @returns {boolean}
 */
const isNullOrUndefined = (arg) => {
	if (isNull(arg)) {
		return true;
	}

	if (isUndefined(arg)) {
		return true;
	}

	return false;
};

export default isNullOrUndefined;
