export const LUCKY_SIX_BALL_COLORS = ["red", "orange", "yellow", "green", "blue", "violet", "purple", "black"];

export const LUCKY_SIX_LIVE_BALL_COLORS = [
	{
		stroke: "#666666",
		stop1: "#484848",
		stop2: "#000000"
	},
	{
		stroke: "#FF075A",
		stop1: "#FF0055",
		stop2: "#330011"
	},
	{
		stroke: "#E96B2C",
		stop1: "#FF7733",
		stop2: "#7C300A"
	},
	{
		stroke: "#E9D223",
		stop1: "#FFEA2A",
		stop2: "#795700"
	},
	{
		stroke: "#10FF21",
		stop1: "#0DFF1F",
		stop2: "#003304"
	},
	{
		stroke: "#02B9C7",
		stop1: "#03D5E5",
		stop2: "#002F33"
	},
	{
		stroke: "#4F00FB",
		stop1: "#661FFF",
		stop2: "#210066"
	},
	{
		stroke: "#CE0CFF",
		stop1: "#C600F7",
		stop2: "#4A045C"
	}
];

/*
 *  By dividing number % 8, the result will point to the index of the color in this map
 * */
export const NUMBER_TO_COLOR_MAPPER = ["black", "red", "orange", "yellow", "green", "blue", "violet", "purple"];

export const BALL_POSITION_TO_STAT_ITEM = {
	FIRST: "item1",
	LAST: "item2"
};

export const LUCKY_SIX_MARKETS = {
	FIRST_BALL_COLOR: "FirstBallColour",
	LAST_BALL_COLOR: "LastBallColour",
	FIRST_BALL_OVER_UNDER: "FirstNumberOverUnder",
	LAST_BALL_OVER_UNDER: "LastNumberOverUnder",
	FIRST_BALL_ODD_EVEN: "FirstNumberOddEven",
	LAST_BALL_ODD_EVEN: "LastNumberOddEven",
	SUM_FIVE_TOTAL_OVER_UNDER: "Sum5TotalOverUnder",
	SUM_FIVE_TOTAL_ODD_EVEN: "Sum5TotalOddEven",
	MOSTLY_DRAWN_ODD_EVEN: "EvenMoreOdd"
};

export const BALL_POSITION = {
	FIRST: "first",
	LAST: "last"
};

export const LUCKY_SUBMIT_KEY = "luckySubmitKey"
