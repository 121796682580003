import { useContext } from "react";

import ElementsContext from "contexts/elements";

const useElements = () => {
	const { getElementFromStore, setElementToStore, removeElementFromStore } = useContext(ElementsContext);

	return {
		getElementFromStore,
		setElementToStore,
		removeElementFromStore
	};
};

export default useElements;
