import {
	placeBetThunk,
	placeQuickBetThunk,
	pendingBetsThunk,
	cancelBetSlipThunk,
	cancelSingleBetThunk
} from "./thunks";

import {
	setBetslipAction,
	setStakeAction,
	updateBetStakeAction,
	setModeAction,
	setStakeModeAction,
	setRigthbarTabAction,
	setShowKeyboardAction,
	setShowMobileBetSlipsModalAction,
	setShowMobileQuickBetSlipAction,
	addBetslipResultAction,
	clearBetslipResultsAction,
	addBetAction,
	updateMatchBetsAction,
	removeBetAction,
	removePendingBetAction,
	removePendingAction,
	updatePendingAction,
	updatePendingBetAction,
	clearBetsAction,
	clearAllTypeBetsAction,
	clearHiddenBetsAction,
	placeBetSuccessAction
} from "./actions";

import { filterFnUpdateMatchBets, mapperFnUpdateMatchBets, mapperFnUpdateBets } from "utils/bets";

import { GAME_STATUSES } from "constants/game.constants";
import { BETSLIP_MODES } from "constants/betslip.constants";

export const addBetslipCases = (builder) => {
	builder.addCase(setBetslipAction, (state, { payload }) => {
		if (payload.betslip.stake !== undefined) {
			state.stake = payload.betslip.stake;
		}
		if (payload.betslip.stakeMode !== undefined) {
			state.stakeMode = payload.betslip.stakeMode;
		}
		if (payload.betslip.mode !== undefined) {
			state.mode = payload.betslip.mode;
		}
		if (payload.betslip.bets !== undefined) {
			state.bets = payload.betslip.bets;
		}
	});

	builder.addCase(setShowMobileBetSlipsModalAction, (state, { payload }) => {
		state.showMobileBetslipsModal = payload.show;
	});

	builder.addCase(setShowMobileQuickBetSlipAction, (state, { payload }) => {
		state.showMobileQuickBetslip = payload.show;
	});
};

export const addPlaceBetCases = (builder) => {
	builder.addCase(placeBetThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(placeBetThunk.fulfilled, (state, { payload: data }) => {
		const mappedData = mapperFnUpdateBets(data);
		state.success = true;
		state.loading = false;

		if (data.type === BETSLIP_MODES.SINGLE) {
			state.pendings = [...Object.values(mappedData), ...state.pendings];
			return;
		}

		state.pendings = [mappedData, ...state.pendings];
	});

	builder.addCase(placeBetThunk.rejected, (state, { error }) => {
		state.loading = false;
	});

	builder.addCase(placeQuickBetThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(placeQuickBetThunk.fulfilled, (state, { payload: data }) => {
		const mappedData = mapperFnUpdateBets(data);

		state.loading = false;

		if (data.type === BETSLIP_MODES.SINGLE) {
			state.pendings = [...Object.values(mappedData), ...state.pendings];
			return;
		}

		state.pendings = [mappedData, ...state.pendings];
	});

	builder.addCase(placeQuickBetThunk.rejected, (state, { error }) => {
		state.loading = false;
	});

	builder.addCase(placeBetSuccessAction, (state, { payload }) => {
		state.success = payload.success;
	})
};

export const cancelBetCases = (builder) => {
	builder.addCase(cancelBetSlipThunk.pending, (state) => {
		state.isCanceling = true;
	});

	builder.addCase(cancelBetSlipThunk.fulfilled, (state) => {
		state.isCanceling = false;
	});

	builder.addCase(cancelBetSlipThunk.rejected, (state, { error }) => {
		state.isCanceling = false;
		console.log(error);
	});

	builder.addCase(cancelSingleBetThunk.pending, (state) => {
		state.isCanceling = true;
	});

	builder.addCase(cancelSingleBetThunk.fulfilled, (state) => {
		state.isCanceling = false;
	});

	builder.addCase(cancelSingleBetThunk.rejected, (state, { error }) => {
		state.isCanceling = false;
		console.log(error);
	});
};

export const addStakeCases = (builder) => {
	builder.addCase(setStakeAction, (state, { payload: { stake } }) => {
		state.stake = stake;
	});

	builder.addCase(updateBetStakeAction, (state, { payload }) => {
		state.bets = state.bets.map((b) => (b.key !== payload.key ? b : { ...b, stake: payload.stake }));
	});
}

export const addModeCases = (builder) => {
	builder.addCase(setModeAction, (state, { payload: { mode } }) => {
		state.mode = mode;
	});

	builder.addCase(setStakeModeAction, (state, { payload: { mode } }) => {
		state.stakeMode = mode;
	});
}

export const addKeyboardCases = (builder) => {
	builder.addCase(setShowKeyboardAction, (state, { payload }) => {
		state.showKeyboard = payload.show;
	});
}

export const addBetslipResultsCases = (builder) => {
	builder.addCase(addBetslipResultAction, (state, { payload }) => {
		state.betslipLastResults = [...payload.data, ...state.betslipLastResults];
	});

	builder.addCase(clearBetslipResultsAction, (state) => {
		state.betslipLastResults = [];
	});
}

export const addBetCases = (builder) => {
	builder.addCase(addBetAction, (state, { payload }) => {
		const stateKey = payload.isQuickBet ? "quickBets" : "bets";
		state.success = false;
		state[stateKey] = [{ ...payload.bet, isExpired: false }, ...state[stateKey]];
	});

	builder.addCase(updateMatchBetsAction, (state, { payload }) => {
		if (state.bets.length === 0) {
			return;
		}
		const isFinished_updateMatchBets = payload.event.status === GAME_STATUSES.FINISHED;
		const isExpired_updateMatchBets = payload.event.status === GAME_STATUSES.STARTED || payload.event.status === GAME_STATUSES.CLOSE_FOR_BETTING;

		if (!isFinished_updateMatchBets && !isExpired_updateMatchBets) {
			return;
		}

		const mapped_updateMatchBets = mapperFnUpdateMatchBets(isExpired_updateMatchBets, filterFnUpdateMatchBets(isFinished_updateMatchBets, state, payload), payload);
		if (state.bets === mapped_updateMatchBets) {
			return;
		}

		state.bets = mapped_updateMatchBets;
	})
}

export const addRemoveBetsCases = (builder) => {
	builder.addCase(removeBetAction, (state, { payload }) => {
		const key = payload.isQuickBet? "quickBets" : "bets";

		const updated_after_remove = state[key].filter((bet) => (
			(payload.betId && bet.betId !== payload.betId) ||
			(payload.eventId && bet.eventId !== payload.eventId) ||
			(payload.key && bet.key !== payload.key)
		));

		state[key] = updated_after_remove;
		state.stake = updated_after_remove.length > 0 || !payload.removeStake ? state.stake : "";
	});

	builder.addCase(clearBetsAction, (state, { payload }) => {
		state[payload.isQuickBet ? "quickBets" : "bets"] = [];
	});

	builder.addCase(clearAllTypeBetsAction, (state) => {
		state.quickBets = [];
		state.bets = [];
	});

	builder.addCase(clearHiddenBetsAction, (state) => {
		state.bets = state.bets.filter((b) => !b.hidden);
	})
}

export const addPendingBetsCases = (builder) => {
	builder.addCase(pendingBetsThunk.pending, (state) => {
		state.pendingLoading = true;
	});

	builder.addCase(pendingBetsThunk.fulfilled, (state, { payload: data }) => {
		state.pendingLoading = false;
		state.pendings = data ?? [];
	});

	builder.addCase(pendingBetsThunk.rejected, (state, { error }) => {
		state.pendingLoading = false;
	});

	builder.addCase(removePendingAction, (state, { payload }) => {
		state.pendings = state.pendings.filter((p) => p.betSlipId !== payload.pending.id);
	});

	builder.addCase(removePendingBetAction, (state, { payload }) => {
		state.pendings = state.pendings.filter((p) => p.betId !== payload.bet.id);
	});

	builder.addCase(updatePendingAction, (state, { payload }) => {
		if (payload.pending.type === BETSLIP_MODES.SINGLE) {
			return;
		}

		const { id: betSlipId, ...rest } = payload.pending;

		const mappedData = mapperFnUpdateBets({ betSlipId, ...rest });

		state.pendings = state.pendings.map((p) => p.betSlipId !== mappedData.betSlipId ? p : { ...mappedData, bets: p.bets });
	});

	builder.addCase(updatePendingBetAction, (state, { payload }) => {
		if (payload.bet.type === BETSLIP_MODES.SINGLE) {
			const mappedData = mapperFnUpdateBets({ bets: [payload.bet] });

			state.pendings = state.pendings.map((p) => p.betId !== payload.bet.id ? p : { ...mappedData[payload.bet.id], betSlipId: p.betSlipId });
			return;
		}

		const mappedData = mapperFnUpdateBets(payload.bet);

		state.pendings = state.pendings.map((p) => {
			if (p.type === BETSLIP_MODES.MULTI) {
				return ({
					...p,
					bets: p.bets.map((bet) => bet.betId === payload.bet.id ? { ...mappedData, betSlipId: p.betSlipId } : bet)
				})
			}

			return p;
		});
	});
}

export const addRightbarTabCases = (builder) => {
	builder.addCase(setRigthbarTabAction, (state, { payload }) => {
		state.rightbarTab = payload.tab;
	});
}