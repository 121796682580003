import isValidObject from "utils/typeChecks/isValidObject";

const setElementStyles = (element, styleOptions) => {
	if (!isValidObject(styleOptions)) {
		return console.error("styleOptions should be object that contains style properties");
	}

	for (const option in styleOptions) {
		if (Object.prototype.hasOwnProperty.call(styleOptions, option)) {
			element.style[option] = styleOptions[option];
		}
	}
};

export default setElementStyles;
