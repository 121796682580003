import { forwardRef } from "react";

import { INITIAL_STYLES } from "./constants";

const FallbackElement = forwardRef(({ onTransitionEnd }, ref) => {
	return (
		<div
			style={{
				...INITIAL_STYLES
			}}
			onTransitionEnd={onTransitionEnd}
			ref={ref}
		/>
	);
});

export default FallbackElement;
