import isArray from "./isArray";
import isNull from "./isNull";

/**
 * Check argument is valid object
 * @function
 * @description valid object - typeof object except null and array
 * @param {any} arg possibly valid object
 * @returns {boolean}
 */
const isValidObject = (arg) => {
	if (isNull(arg)) {
		return false;
	}

	if (isArray(arg)) {
		return false;
	}

	return typeof arg === "object";
};

export default isValidObject;
