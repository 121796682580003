export const FLY_ELEMENT_OPTIONS = {
	BET_SLIP_DESKTOP: {
		offsetX: 8,
		offsetY: 17,
		target: "betSlip",
		transitionStyles: {
			width: "344px",
			height: "30px"
		}
	},

	BET_SLIP_MOBILE: {
		offsetX: 5,
		offsetY: 5,
		target: "betSlipBadge",
		transitionStyles: {
			width: "10px",
			height: "10px"
		}
	},

	BET_HISTORY: {
		offsetX: 5,
		offsetY: 5,
		target: "betHistoryBadge",
		transitionStyles: {
			width: "10px",
			height: "10px"
		}
	}
};
