/**
 * Check argument is array
 * @function
 * @param {any} arg possibly array
 * @returns {boolean}
 */
const isArray = (arg) => {
	return Array.isArray(arg);
};

export default isArray;
