import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { setShowKeyboardAction } from "store/slices/betslip/actions";

import SingleBet from "./singleBet";
import MobileSingleBet from "./mobileSingleBet";
import BetSlipEmpty from "./empty";
import BetSlipSuccess from "./success";
import BetSlipTabs from "./betslipTabs";
import Stake from "./stake";
import ScrollBar from "components/ui/scrollBar";

import useGlobalVariables from "hooks/useGlobalVariables";
import useDocumentListener from "hooks/useDocumentListener";
import useBetTicketBorderSvgProps from "hooks/betslip/useBetTicketBorderSvgProps";

import { selectIsQuickBetEnabled } from "store/slices/auth/selectors";
import { selectBets, selectPlaceBetSuccess, selectBetslipMode } from "store/slices/betslip/selectors";

import { mergeClassNames } from "utils/common";
import { getEventsRepeatedBets } from "utils/bets";

import { BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS } from "constants/betslip.constants";
import useBetSlipModeAutoChanger from "hooks/useBetSlipModeAutoChanger";
import useElements from "hooks/useElements";

/* Betslip Tab Component */
const BetSlip = () => {
	const [focusedStakeId, setFocusedStakeId] = useState(null);
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();

	const { setElementToStore, removeElementFromStore } = useElements();

	const betSlipRef = useRef(null);

	const bets = useAppSelector(selectBets);
	const success = useAppSelector(selectPlaceBetSuccess);
	const mode = useAppSelector(selectBetslipMode);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);

	const scrollBarRef = useRef();
	const dispatch = useAppDispatch();

	const groupedRepeatedBets = useMemo(() => getEventsRepeatedBets(bets, mode), [bets, mode]);
	const borderSvgStyle = useBetTicketBorderSvgProps(isMobile ? "--vs--bg-color" : "--vs--bg-color-base");

	useEffect(() => {
		setElementToStore("betSlip", betSlipRef.current);

		return () => {
			removeElementFromStore("betSlip");
		};
	}, []);

	useDocumentListener("click", (event) => {
		if (event?.target) {
			const hasDataAttribute = (element, attr) => {
				return element.closest(`[${attr}]`) !== null;
			};

			const hasRelevantAttribute = Object.values(BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS).some((attr) =>
				hasDataAttribute(event.target, attr)
			);

			if (!hasRelevantAttribute) {
				setFocusedStakeId(null);

				if (isMobile) {
					dispatch(setShowKeyboardAction(false));
				}
			}
		}
	});

	const { nonHidden } = useBetSlipModeAutoChanger();

	return (
		<Fragment>
			{nonHidden.length > 0 && !isQuickBetEnabled && <BetSlipTabs scrollBarRef={scrollBarRef} />}
			<div className="vs--rightBar-content" data-element-id="RightBarContent" ref={betSlipRef}>
				<ScrollBar className="vs--scrollbar-thin vs--rightBar-scrollbar" vertical={true} ref={scrollBarRef}>
					{nonHidden.length > 0 && !isQuickBetEnabled ? (
						<div
							className={mergeClassNames(
								"vs--betslip-content",
								isMobile ? "vs--mt-4" : "vs--pl-8 vs--pr-8 vs--pb-8 vs--pt-16"
							)}
						>
							{nonHidden.map((bet, ind) => {
								if (isMobile) {
									return (
										<div className="vs--betslip-content-card" key={bet.key}>
											<MobileSingleBet
												borderSvgStyle={borderSvgStyle}
												bet={bet}
												index={ind}
												groupedRepeatedBets={groupedRepeatedBets}
												focusedStakeId={focusedStakeId}
												setFocusedStakeId={setFocusedStakeId}
											/>
										</div>
									);
								}
								return (
									<div className="vs--betslip-content-card" key={bet.key}>
										<SingleBet
											borderSvgStyle={borderSvgStyle}
											bet={bet}
											index={ind}
											groupedRepeatedBets={groupedRepeatedBets}
											focusedStakeId={focusedStakeId}
											setFocusedStakeId={setFocusedStakeId}
										/>
									</div>
								);
							})}
						</div>
					) : success && !isMobile && !isQuickBetEnabled ? (
						<BetSlipSuccess />
					) : (
						<BetSlipEmpty
							isQuickBetEnabled={isQuickBetEnabled}
							text={isQuickBetEnabled ? t("bet.quickBetText") : t("bet.betslipEmptyText")}
							subtext={isQuickBetEnabled ? t("bet.quickBetSubText") : t("bet.betslipEmptySubtext")}
						/>
					)}
				</ScrollBar>
				{!isMobile && (nonHidden.length > 0 || isQuickBetEnabled) && (
					<Stake
						groupedRepeatedBets={groupedRepeatedBets}
						focusedStakeId={focusedStakeId}
						setFocusedStakeId={setFocusedStakeId}
					/>
				)}
				{isMobile && (nonHidden.length > 0 || isQuickBetEnabled) && (
					<Stake
						groupedRepeatedBets={groupedRepeatedBets}
						focusedStakeId={focusedStakeId}
						setFocusedStakeId={setFocusedStakeId}
					/>
				)}
			</div>
		</Fragment>
	);
};

export default BetSlip;
