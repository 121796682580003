import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import Navbar from "components/ui/navbar";

import useAppSelector from "hooks/store/useAppSelector";

import { selectPendingBets } from "store/slices/betslip/selectors";
import { selectIsQuickBetEnabled, selectIsSessionPlayerBonusAcquired } from "store/slices/auth/selectors";

import { navigateToPage } from "utils/navigate";
import { MENU_ITEMS } from "constants/menu.contants";
import Paths from "constants/path.constants";
import useElements from "hooks/useElements";
import { useEffect, useRef } from "react";
import { mergeClassNames } from "utils/common";
import useUpdateEffect from "hooks/useUpdateEffect";

/* Menu Component in Header */
const Menu = ({ dir }) => {
	const isSessionPlayerBonusAcquired = useAppSelector(selectIsSessionPlayerBonusAcquired);

	const filterItemCb = (item) => {
		if (item.path !== Paths.BONUS) {
			return true;
		}

		return isSessionPlayerBonusAcquired;
	};

	return (
		<div className="vs--flex vs--justify-between vs-align-center vs--flex-equal">
			<Navbar
				dir={dir}
				menuButton={false}
				elementId="id"
				className="vs--header-menu"
				dataList={MENU_ITEMS.filter((item) => filterItemCb(item))}
				elementInnerComponent={Menu.elementInnerComponent}
			/>
		</div>
	);
};

Menu.elementInnerComponent = ({ data: item }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);

	const betHistoryBadgeRef = useRef(null);

	const { setElementToStore, removeElementFromStore } = useElements();

	const pendings = useAppSelector(selectPendingBets);

	const prevPendingsCountRef = useRef(pendings.length);

	useEffect(() => {
		if (item.path !== Paths.HISTORY) {
			return;
		}

		setElementToStore("betHistoryBadge", betHistoryBadgeRef.current);

		return () => {
			removeElementFromStore("betHistoryBadge");
		};
	}, [item.path]);

	useUpdateEffect(() => {
		if (item.path !== Paths.HISTORY || !isQuickBetEnabled) {
			return;
		}

		if (pendings.length <= prevPendingsCountRef.current) {
			prevPendingsCountRef.current = pendings.length;
			return;
		}

		prevPendingsCountRef.current = pendings.length;

		setTimeout(() => {
			if (!betHistoryBadgeRef.current) {
				return;
			}

			betHistoryBadgeRef.current.classList.add("vs--badge-blink");
		}, 1900);

		setTimeout(() => {
			if (!betHistoryBadgeRef.current) {
				return;
			}

			betHistoryBadgeRef.current.classList.remove("vs--badge-blink");
		}, 2100);
	}, [pendings.length, item.path, isQuickBetEnabled]);

	/** Function to check if the menu item is active
	 * @function
	 * @param {object} item - menu item to check
	 * @returns {boolean}
	 * @memberOf Menu
	 */
	const isActiveLink = (item) =>
		item.path !== "/"
			? location.pathname.includes(item.path) ||
				(item.pathsToSelect && item.pathsToSelect.some((p) => location.pathname.includes(p.replace(":id", ""))))
			: location.pathname === item.path;

	return (
		<div
			key={item.id}
			className={
				"vs--header-menu-item vs--flex vs--align-center vs--pl-24 vs--pr-24" +
				(isActiveLink(item) ? " vs--header-menu-item-active" : "")
			}
			onClick={() =>
				item.onClick ? item.onClick() : !isActiveLink(item) ? navigateToPage(navigate, item.path) : false
			}
			data-unique={item.id}
		>
			<span className="vs--header-menu-item-title vs--title-gray-50 vs--font-regular vs--font-normal">
				{t(item.title)}
			</span>
			{item.path === Paths.HISTORY && (
				<b
					data-element-id="badge"
					className={mergeClassNames(
						"vs--badge vs--title-black vs--font-smallest vs--font-bold vs--ml-6",
						pendings.length === 0 && "vs--visually-hidden"
					)}
					ref={betHistoryBadgeRef}
				>
					{pendings.length}
				</b>
			)}
		</div>
	);
};

Menu.elementInnerComponent.displayName = "MenuElementInnerComponent";
Menu.elementInnerComponent.propTypes = {
	data: PropTypes.any
};

export default Menu;

Menu.propTypes = {
	dir: PropTypes.string
};
