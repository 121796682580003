import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import KenoBalls from "components/keno/bets/kenoBalls";
import BetTicketBorder from "components/ui/betTicketBorder";
import Input from "components/ui/input";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import {
	BETSLIP_MODES,
	BETSLIP_STAKE_MODES,
	BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS
} from "constants/betslip.constants.js";
import { GAME_TYPE, GAME_TYPE_ICON_MAPPER, KENO_BALL_SIZE } from "constants/game.constants";
import { BONUS_TYPE } from "constants/bonus.constants";

import {
	removeBetAction,
	setShowKeyboardAction,
	setShowMobileQuickBetSlipAction,
	updateBetStakeAction
} from "store/slices/betslip/actions";
import { selectUseBonus } from "store/slices/bonus/selectors";
import {
	selectBetslipMode,
	selectBetslipStakeMode,
	selectBets,
	selectBetslipStake
} from "store/slices/betslip/selectors";
import {
	selectSessionCurrency,
	selectOddFormat,
	selectSessionCurrentBonus,
	selectIsQuickBetEnabled,
	selectQuickBetAmount,
	selectIsDemoSession
} from "store/slices/auth/selectors";

import { getBetEventName } from "utils/bets";
import { numberWithSpaces, makeCurrencyText, toFixedFloor, mergeClassNames, validateForAmount } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";

import betType from "types/bet.type";
import BetBalls from "components/luckySix/betBalls/index.jsx";

/** SIngle Bet Component in Betslip */
const MobileSingleBet = ({
	amount,
	isQuickBetslipOn = false,
	borderSvgStyle,
	bet,
	index,
	focusedStakeId = null,
	setFocusedStakeId = Function.prototype,
	groupedRepeatedBets
}) => {
	const { t } = useTranslation();
	const didMountRef = useRef(false);
	const useBonus = useAppSelector(selectUseBonus);
	const currentBonus = useAppSelector(selectSessionCurrentBonus);
	const isFreeAmountBonus = useBonus && currentBonus?.bonusType === BONUS_TYPE.FREEAMOUNT;
	const sessionCurrency = useAppSelector(selectSessionCurrency);
	const isDemo = useAppSelector(selectIsDemoSession);
	const oddFormat = useAppSelector(selectOddFormat);
	const mode = useAppSelector(selectBetslipMode);
	const stakeMode = useAppSelector(selectBetslipStakeMode);
	const bets = useAppSelector(selectBets);
	const stake = useAppSelector(selectBetslipStake);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const quickBetAmount = useAppSelector(selectQuickBetAmount);

	const dispatch = useAppDispatch();

	// true if user can't change stake
	const readOnly = useBonus && !isFreeAmountBonus;
	const isQuickBetStakeDisabled = isQuickBetEnabled ? isDemo : false;
	const elemRef = useRef(null);
	const draggingXRef = useRef(0);
	const draggingYRef = useRef(0);
	const draggingXMoveRef = useRef(0);
	const removeVisibleRef = useRef(false);

	const getMinMaxBetAmountError = (value) => {
		if (Number(value) < (sessionCurrency.singleMin ?? 0) && value !== "") {
			return { limit: sessionCurrency.singleMin ?? Infinity, message: t("bet.minBetAmountIs") };
		}
		if (Number(value) > (sessionCurrency.singleMax ?? Infinity) && value !== "") {
			return { limit: sessionCurrency.singleMax ?? Infinity, message: t("bet.maxBetAmountIs") };
		}

		return null;
	};

	const minMaxBetAmountError = getMinMaxBetAmountError(bet.stake);

	/** Function which checks if the stake is valid
	 * @function
	 * @param {string} value - stake value
	 * @returns {boolean}
	 * @memberOf MobileSingleBet
	 */
	const hasError = () => Boolean(minMaxBetAmountError);

	/** Events for dragging */

	const handleRemoveBet = (key, betId, eventId) => {
		dispatch(removeBetAction({ key, betId, eventId }));
		setFocusedStakeId(null);
	};

	/** Function to remove bet frombetslip by animation
	 * @function
	 * @memberOf MobileSingleBet
	 */
	const removeBetFromBetslip = () => {
		elemRef.current.style.maxHeight = "0";
		dispatch(setShowMobileQuickBetSlipAction(false));
		setTimeout(() => {
			handleRemoveBet(bet.key);
		}, 240);
	};

	/** Update stake input value on "stake per bet switcher" or common stake input value change */
	useEffect(() => {
		if (didMountRef.current) {
			if (stakeMode === BETSLIP_STAKE_MODES.PER_BET) {
				if (!bet.isExpired) {
					dispatch(updateBetStakeAction(stake, bet.key));
				}
			} else {
				if (!bet.isExpired) {
					const notExpiredBets = bets.filter((b) => !b.isExpired);
					const notExpiredBetsIndexes = bets
						.map((b, index) => ({ isExpired: b.isExpired, index: index }))
						.filter((b) => !b.isExpired)
						.map((b) => b.index);

					dispatch(
						updateBetStakeAction(
							stake === ""
								? stake
								: index !== notExpiredBetsIndexes[notExpiredBetsIndexes.length - 1]
									? toFixedFloor(Number(stake) / notExpiredBets.length, sessionCurrency.decimalCount ?? 2)
									: toFixedFloor(
											Number(stake) -
												Number(
													toFixedFloor(
														Number(stake) / notExpiredBets.length,
														sessionCurrency.decimalCount ?? 2
													)
												) *
													notExpiredBets.length +
												Number(
													toFixedFloor(
														Number(stake) / notExpiredBets.length,
														sessionCurrency.decimalCount ?? 2
													)
												),
											sessionCurrency.decimalCount ?? 2
										),
							bet.key
						)
					);
				}
			}
		} else didMountRef.current = true;
	}, [stake, stakeMode, useBonus]);

	useEffect(() => {
		if (bet.isExpired) {
			setTimeout(() => {
				handleRemoveBet(bet.key);
			}, 3000);
		}
	}, [bet.isExpired]);

	/** Close keyboard in case of multi, or when bet gets expired */
	useEffect(() => {
		if (mode === BETSLIP_MODES.MULTI || bet.isExpired) {
			dispatch(setShowKeyboardAction(false));
		}
	}, [bet.isExpired, mode]);

	/** Cleaning focused Bet stake on Unmount */
	useEffect(() => {
		return () => {
			setFocusedStakeId(null);
		};
	}, []);

	return (
		<div style={{ position: "relative" }}>
			<div className={mergeClassNames("vs--betslip-section", mode === BETSLIP_MODES.SINGLE && "vs--mb-4")}>
				<div
					className={mergeClassNames("vs--betslip-bet", bet.isExpired && "vs--single-bet-expired")}
					ref={elemRef}
				>
					<div className="vs--betslip-single-bet-content">
						{!isQuickBetslipOn && (
							<div
								className={mergeClassNames(
									"vs--betslip-single-bet-content-header",
									groupedRepeatedBets?.[bet.eventId] &&
										"vs--betslip-single-bet-content-header-combined-event-error",
									"vs--flex vs--align-center vs--justify-between vs--pl-12 vs--pr-12"
								)}
							>
								<div className="vs--flex vs--align-center">
									{bet.isExpired && (
										<span className="vs--betslip-single-bet-content-header-dot vs--mr-8"></span>
									)}
									<i className={GAME_TYPE_ICON_MAPPER[bet.gameType] || null} />
									<span className="vs--betslip-single-bet-content-header-event vs--title-white vs--font-medium vs--font-small vs--pl-4">
										{getBetEventName(bet)}
									</span>
								</div>
								{bet.isExpired ? (
									<span className="vs--title-red vs--font-medium vs--font-small">{t("bet.expired")}</span>
								) : (
									<i className="ic_close vs--font-20" onClick={removeBetFromBetslip} />
								)}
							</div>
						)}
						<div className="vs--betslip-single-bet-content-body vs--pl-8 vs--pr-8 vs--pt-4 vs--pb-8">
							<div className="vs--betslip-single-bet-content-body-market-group vs--font-regular vs--font-small vs--flex vs--align-center vs--flex-start">
								{bet.group}
								{isQuickBetslipOn ? (
									<i className="vs--ml-auto ic_close vs--font-20" onClick={removeBetFromBetslip} />
								) : null}
							</div>
							<div className="vs--flex vs--justify-between vs--align-center vs--mt-4 vs--mb-4">
								{bet?.gameType === GAME_TYPE.KENO ? (
									<KenoBalls
										balls={bet.name.split(",").map((b) => Number(b))}
										size={KENO_BALL_SIZE.SMALL}
										splited={bet.type === BETSLIP_MODES.SINGLE}
									/>
								) : bet?.gameType === GAME_TYPE.LUCKY_SIX && bet.name.includes(",") ? (
									<div className="vs--lucky-six-balls">
										<BetBalls numbers={bet.name.split(",").map((b) => Number(b))} clickable={false} />
									</div>
								) : (
									<b
										className="vs--title-brand vs--font-medium vs--font-normal"
										dangerouslySetInnerHTML={{ __html: `${bet.name} ${bet.argument ?? ""}` }}
									></b>
								)}
								<b className="vs--title-brand vs--font-medium vs--font-normal vs--text-right vs--pl-16 vs--pr-4">
									{numberWithSpaces(runMarketUtilsFunction("convertToFormat", [bet.factor, oddFormat]))}
								</b>
							</div>
							<div className="vs--flex vs--justify-between">
								{mode === BETSLIP_MODES.SINGLE && !bet.isExpired && (
									<Input
										wrapperProps={{
											[BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS.BET_STAKE]: true
										}}
										className={mergeClassNames(
											"vs--betslip-input",
											focusedStakeId === bet.key && "vs--betslip-input-focused",
											isQuickBetslipOn && "vs--betslip-input-quick",
											hasError() ? " vs--stake-input-error" : "",
											readOnly ? " vs--stake-input-disabled" : ""
										)}
										placeholder={t("bet.stake")}
										readOnly={true}
										disabled={isQuickBetStakeDisabled}
										value={numberWithSpaces(isQuickBetslipOn && isQuickBetEnabled ? amount : bet.stake)}
										onFocus={() => setFocusedStakeId(bet.key)}
										onClick={() => {
											dispatch(setShowKeyboardAction(!readOnly && !isQuickBetStakeDisabled));
										}}
										prefix={
											isQuickBetslipOn && isQuickBetEnabled && Number(amount) === Number(quickBetAmount)
												? () => <i className="ic_check vs--title-green" />
												: null
										}
										suffix={
											useBonus && (
												<i className="ic_bonus vs--stake-input-bonus-icon vs--stake-input-bonus-icon-right" />
											)
										}
									/>
								)}
								{mode === BETSLIP_MODES.SINGLE ? (
									<div
										className={
											"vs--single-bet-stake vs--pr-4" + (hasError() ? " vs--single-bet-stake-error" : "")
										}
									>
										{minMaxBetAmountError !== null ? (
											<div className="vs--single-bet-stake-text vs--flex vs--flex-col vs--justify-center vs--text-right vs--single-bet-stake-error">
												<span className="vs--title-red vs--font-regular vs--font-small vs--pb-4">
													{minMaxBetAmountError.message}
												</span>
												<span className="vs--title-red vs--font-medium vs--font-small vs--text-ltr">
													{makeCurrencyText(minMaxBetAmountError.limit, {
														currencyId: sessionCurrency?.currencyId ?? 0,
														decimalCount: sessionCurrency?.decimalCount ?? 0
													})}
												</span>
											</div>
										) : (
											<div className="vs--single-bet-stake-text vs--flex vs--flex-col vs--justify-center vs--text-right">
												<span className="vs--font-bold vs--font-smallest vs--single-bet-stake-text-title vs--pb-4">
													{t("bet.possibleWin")}
												</span>
												<div className="vs--flex vs--justify-end">
													<span className="vs--title-white vs--font-medium vs--font-small vs--text-ltr vs--mr-4">
														{makeCurrencyText(bet.stake * bet.factor, {
															currencyId: sessionCurrency?.currencyId ?? 0,
															decimalCount: sessionCurrency?.decimalCount ?? 0
														})}
													</span>

													<span className="vs--title-white vs--font-medium vs--font-small vs--text-ltr">
														{sessionCurrency?.code}
													</span>
												</div>
											</div>
										)}
									</div>
								) : null}
							</div>
						</div>
					</div>
					<BetTicketBorder svgProps={borderSvgStyle} />
				</div>
			</div>
		</div>
	);
};

/** MobileSingleBet propTypes
 * PropTypes
 */
MobileSingleBet.propTypes = {
	/** The bet of component */
	bet: betType,
	/** Index of bet in bets array */
	index: PropTypes.number,
	/** Redux state property, bonus usage property */
	useBonus: PropTypes.bool,
	/** React prop, bets on the same events */
	groupedRepeatedBets: PropTypes.object,
	/** React property, focused Betslip/Bet stake  */
	focusedStakeId: PropTypes.string,
	/** React property, set last focused Betslip/Bet stake input  */
	setFocusedStakeId: PropTypes.func
};

export default MobileSingleBet;
