import { useState } from "react";

const useProcessingQuickBetsStore = () => {
	const [processingQuickBetsStore, setProcessingQuickBetsStore] = useState({
		/**
		 * [betKey]: pending | successful | rejected
		 */
	});

	const addOrUpdate = (betKey, state) => {
		setProcessingQuickBetsStore((prevState) => {
			return {
				...prevState,
				[betKey]: state
			};
		});
	};

	const remove = (betKey) => {
		setProcessingQuickBetsStore((prevState) => {
			const prevStateClone = { ...prevState };

			delete prevStateClone[betKey];

			return prevStateClone;
		});
	};

	return {
		processingQuickBetsStore,
		addOrUpdate,
		remove
	};
};

export default useProcessingQuickBetsStore;
