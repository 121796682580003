import { useRef } from "react";

import useUpdateEffect from "hooks/useUpdateEffect";
import isNull from "utils/typeChecks/isNull";

import { TOAST_TYPE } from "../../constants";

import "./index.scss";
import { mergeClassNames } from "utils/common";

const ToastView = ({ count, message, type }) => {
	const countRef = useRef(null);

	useUpdateEffect(() => {
		if (isNull(countRef.current) || count === 1) {
			return;
		}

		countRef.current.classList.add("vs--notification-view-count-bounce");
	}, [count]);

	const handleAnimationEnd = () => {
		countRef.current.classList.remove("vs--notification-view-count-bounce");
	};

	return (
		<div
			className={mergeClassNames(
				"vs--notification-view",
				type === TOAST_TYPE.SUCCESS && "vs--notification-view-success",
				type === TOAST_TYPE.ERROR && "vs--notification-view-error",
				type === TOAST_TYPE.WARN && "vs--notification-view-warn",
			)}
		>
			{count > 1 && (
				<div className="vs--notification-view-count" onAnimationEnd={handleAnimationEnd} ref={countRef}>
					{count}
				</div>
			)}
			<div className="vs--notification-view-message">{message}</div>
		</div>
	);
};

export default ToastView;
