import { Zoom, ToastContainer, toast } from "react-toastify";

import isNull from "utils/typeChecks/isNull";

import ToastView from "./components/toastView";
import { TOAST_TYPE } from "./constants";

import "./index.scss";
import { isRTL } from "utils/common";
import { useSelector } from "react-redux";

const AUTO_CLOSE_TIME = 5000; //ms

const getLanguage = (state) => state.auth.session.languageId;

const Container = () => {
	const languageId = useSelector(getLanguage);
	const rtl = isRTL(languageId);

	return (
		<ToastContainer
			rtl={rtl}
			position={rtl ? "top-left" : "top-right"}
			autoClose={AUTO_CLOSE_TIME}
			newestOnTop={true}
			closeOnClick={true}
			pauseOnFocusLoss={true}
			theme="colored"
			pauseOnHover={false}
			hideProgressBar={false}
			transition={Zoom}
			limit={3}
		/>
	);
}

/**
 * ToastContainer API -> https://fkhadra.github.io/react-toastify/api/toast-container
 * toast API -> https://fkhadra.github.io/react-toastify/api/toast
 */

class ToastManager {
	static _instance = null;

	static get instance() {
		return ToastManager._instance || new ToastManager();
	}

	static ToastContainer() {
		return <Container />
	}

	constructor() {
		if (ToastManager._instance) {
			return ToastManager._instance;
		}

		ToastManager._instance = this;

		this._visibleToasts = {
			/**
			 * [message]: {
			 * 	id: number | null
			 * 	count: number
			 * }
			 */
		};
	}

	#removeExpiredToast(message) {
		delete this._visibleToasts[message];
	}

	#showToast({ message, type }) {
		const { count, id = null } = this._visibleToasts[message] ?? {};

		if (isNull(id)) {
			const newCount = 1;

			const toastId = toast[type](<ToastView count={newCount} message={message} type={type} />, {
				onClose: () => this.#removeExpiredToast(message)
			});

			this._visibleToasts[message] = {
				count: newCount,
				id: toastId
			};
		} else {
			const updatedCount = count + 1;
			this._visibleToasts[message].count = updatedCount;

			toast.update(id, {
				render: <ToastView count={updatedCount} message={message} type={type} />,
				icon: false,
				autoClose: AUTO_CLOSE_TIME
			});
		}
	}

	success(message) {
		this.#showToast({ message, type: TOAST_TYPE.SUCCESS });
	}

	error(message) {
		this.#showToast({ message, type: TOAST_TYPE.ERROR });
	}

	warn(message) {
		this.#showToast({ message, type: TOAST_TYPE.WARN });
	}
}

export default ToastManager;
