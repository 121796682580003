export const INITIAL_STYLES = {
	display: "none",
	transform: "translate(0px, 0px)",
	opacity: "1",
	minWidth: "1px",
	minHeight: "1px",
	position: "fixed",
	top: "",
	left: "",
	width: "",
	height: "",
	backgroundColor: "var(--brand-color-1)",
	zIndex: "10",
	transition: `width 0.4s, height 0.4s, transform 0.4s, opacity 0.4s`,
	willChange: "transform, opacity, width, height",
	pointerEvents: "none"
};

export const INITIAL_STATE = {
	isVisible: false,
	startStyles: {},
	transitionStyles: {},
	offsetX: 0,
	offsetY: 0,
	target: ""
};
