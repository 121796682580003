/**
 * Check argument is undefined
 * @function
 * @param {any} arg possibly undefined
 * @returns {boolean}
 */
const isUndefined = (arg) => {
	return typeof arg === "undefined";
};

export default isUndefined;
