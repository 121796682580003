/**
 * Check argument is null
 * @function
 * @param {any} arg possibly null
 * @returns {boolean}
 */
const isNull = (arg) => {
	return arg === null;
};

export default isNull;
