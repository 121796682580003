import BetSlip from "./partials/betslip";
import PendingBets from "./partials/pendingBets";
import RightBarTabs from "./partials/tabs";


import useAppSelector from "hooks/store/useAppSelector";

import { selectRightbarTab } from "store/slices/betslip/selectors";

/* RightBar Component */
const RightBar = () => {
	const rightbarTab = useAppSelector(selectRightbarTab);

	return (
		<div className="vs--rightBar vs--ml-8">
			<RightBarTabs />
			{rightbarTab === 1 ? <BetSlip /> : <PendingBets />}
		</div>
	);
};

export default RightBar;
