import { Fragment, useEffect } from "react";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAppSelector from "hooks/store/useAppSelector";
import useAppDispatch from "hooks/store/useAppDispatch";

import Tooltip from "components/ui/tooltip"
import Switch from "components/ui/switch";

import useLoginScript from "hooks/auth/useLoginScript";
import useExitFromSession from "hooks/auth/useExitFromSession";

import { makeCurrencyText, mergeClassNames } from "utils/common";
import { formatDate } from "utils/dateTime";

import { useBonusThunk } from "store/slices/bonus/thunks";
import { setShowMobileBetSlipsModalAction } from "store/slices/betslip/actions";
import { 
	selectIsDemoSession, 
	selectSessionCurrency, 
	selectSessionBonuses,
	selectSessionBalance,
	selectIsHideHeaderInMobileFrame,
	selectSessionExitUrl,
	selectSessionLogoId,
	selectSessionProjectId,
	selectSessionPartnerId,
	selectSessionMaintenanceMode,
	selectIsAuthorized,
	selectIsRtl
} from "store/slices/auth/selectors";
import { selectShowMobileBetslipsModal } from "store/slices/betslip/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";

import { redirectToPage } from "utils/navigate";
import Paths from "constants/path.constants";

import { BONUS_TYPE } from "constants/bonus.constants";
import { SWITCH_SIZES } from "constants/ui.constants";
import useGlobalVariables from "hooks/useGlobalVariables";
import ToastManager from "helpers/toastManager";

/** Mobile header Component */
const MobileHeader = () => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const globalVariables = useGlobalVariables();

	const isDemo = useAppSelector(selectIsDemoSession);
	const bonuses = useAppSelector(selectSessionBonuses);
	const balance = useAppSelector(selectSessionBalance);
	const currency = useAppSelector(selectSessionCurrency);
	const isHideHeaderInMobileFrame = useAppSelector(selectIsHideHeaderInMobileFrame);
	const exitUrl = useAppSelector(selectSessionExitUrl);
	const logoId = useAppSelector(selectSessionLogoId);
	const projectId = useAppSelector(selectSessionProjectId);
	const partnerId = useAppSelector(selectSessionPartnerId);
	const maintenanceMode = useAppSelector(selectSessionMaintenanceMode);
	const isAuthorized = useAppSelector(selectIsAuthorized);
	const isRTLLanguage = useAppSelector(selectIsRtl);
	const showMobileBetslipsModal = useAppSelector(selectShowMobileBetslipsModal);
	const useBonus = useAppSelector(selectUseBonus);

	const dispatch = useAppDispatch();

	const exitFromSession = useExitFromSession();
	const runLoginScript = useLoginScript();

	const { isMobile, isDemo: isDemoFromContext } = globalVariables;
	const isDeterminedDemo = isDemoFromContext || isDemo;

	const hideHeaderContent = isMobile && isHideHeaderInMobileFrame;
	const hideHeaderContentReal = hideHeaderContent && !isDeterminedDemo;

	const goBack = () => {
		if (exitUrl) {
			return exitFromSession()
		}

		if (showMobileBetslipsModal) {
			return dispatchEvent(setShowMobileBetSlipsModalAction(false));
		}

		redirectToPage(navigate, Paths.HOME);
	};

	const getHeaderText = (bonus) => {
		if (bonus?.bonusType === BONUS_TYPE.FREEBET) {
			return `${t("bonus.freeBet")} ${t("bonus.bonus")}`;
		}
		if (bonus?.bonusType === BONUS_TYPE.FREEAMOUNT) {
			return `${t("bonus.freeAmount")} ${t("bonus.bonus")}`;
		}

		return "";
	};

	useEffect(() => {
		if (bonuses.length === 0) {
			dispatch(useBonusThunk(false));
		}
	}, [bonuses]);

	/** Function to render bonus/balance JSX
		* @function
		* @returns {JSX}
		* @memberOf MobileHeader
	*/
	const renderBonusAndBalance = () => (
		<div className="vs--flex vs--flex-col vs--align-end vs--justify-center vs--mobile-header-balance">
			<b className="vs--title-white vs--font-small vs--font-regular">{useBonus ? getHeaderText(bonuses[0]) : t("bonus.balance")}</b>
			<span className="vs--title-brand vs--font-small vs--font-regular vs--mt-2">
				{
					useBonus
						? bonuses[0]?.bonusType === BONUS_TYPE.FREEBET
							? `${bonuses[0]?.roundCount ?? 0}x${bonuses[0]?.amount ?? 0} ${currency.code ?? ""}`
							: bonuses[0]?.bonusType === BONUS_TYPE.FREEAMOUNT
								? `${bonuses[0]?.amount ?? 0} ${currency.code ?? ""}`
								: ""
						: makeCurrencyText(balance, !isDemo ? currency : { code: "FUN", decimalCount: 2 })
				}
			</span>
		</div>
	)

	const handleSwitchChange = () => {
		if (!useBonus) {
			ToastManager.instance.success(t("bonus.bonusIsNowActive"))
		}
		dispatch(useBonusThunk(!useBonus));
	}

	if (hideHeaderContentReal && !bonuses.length) {
		return null
	}

	return (
		<div className={mergeClassNames("vs--header vs--ns vs--flex vs--justify-between vs--align-center vs--pl-16 vs--pr-16", ((hideHeaderContentReal && bonuses.length > 0) || hideHeaderContent) && "vs--justify-end")}>
			{
				hideHeaderContent
					? null
					: (
						<div className="vs--flex vs--align-center">
							<div
								className="vs--flex vs--mr-16"
								onClick={goBack}
							>
								<i className="vs--header-back ic_right vs--font-bigest"></i>
							</div>
							{logoId && projectId && partnerId && (
								<img
									alt="logo"
									src={`${import.meta.env.SYSTEM_CDN_URL}/${partnerId}/projects/${projectId}/ui/${logoId}_mobile_logo.png`}
								/>
							)}
						</div>
					)
			}
			{
				!maintenanceMode
					? (
						isAuthorized ? (
							<div className="vs--flex vs--align-center vs--justify-end">
								{
									useBonus
										? (
											<Tooltip
												title={(
													<Fragment>
														<b className="vs--title vs--font-medium vs--font-small vs--mb-2">
															{
																bonuses[0]?.bonusType === BONUS_TYPE.FREEBET ? t('bonus.freeBet') :
																	bonuses[0]?.bonusType === BONUS_TYPE.FREEAMOUNT ? t('bonus.freeAmount') : ""
															}
														</b>
														<b className="vs--title vs--font-regular vs--font-small">{`${t("bonus.availableUntil")}: ${formatDate(bonuses[0]?.endDate ?? "")}`}</b>
													</Fragment>
												)}
												placement="bottomRight"
												overlayClassName="vs--mobile-header-balance-tooltip"
											>
												{renderBonusAndBalance()}
											</Tooltip>
										)
										: hideHeaderContentReal && bonuses.length > 0
											? null
											: renderBonusAndBalance()
								}
								{
									bonuses.length > 0
										? (
											<div className="vs--flex vs--flex-row vs--align-center vs--justify-between vs--mobile-header-bonus vs--ml-8 vs--pl-8">
												<Switch
													dir={isRTLLanguage ? "ltr" : "rtl"}
													onChange={handleSwitchChange}
													checked={useBonus}
													size={SWITCH_SIZES.LARGE}
													className="vs--mobile-header-bonus-content-switch"
													indicatorContent={useBonus ? <i className="ic_bonus vs--font-small" /> : <span className="vs--title-white vs--font-small vs--font-medium">&#36;</span>}
													unCheckedBoxContent={<i className="ic_bonus vs--font-small" />}
													checkedBoxContent={<span className="vs--title-white vs--font-small vs--font-medium">&#36;</span>}
												/>
											</div>
										)
										: null
								}
							</div>
						)
							: (
								<div
									className="vs--flex vs--flex-row vs--align-center vs--justify-end vs--mobile-header-login"
									onClick={() => {
										runLoginScript()
									}}
								>
									<span className="vs--title vs--font-normal vs--font-regular">
										{t("common.login")}
									</span>
								</div>
							)
					)
					: null
			}
		</div>
	);
};

export default MobileHeader;