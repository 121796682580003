import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCurrentPath from "hooks/useCurrentPath";

import { selectCurrentTime } from "store/slices/common/selectors";
import { selectSessionGames } from "store/slices/auth/selectors";


import { selectIsRtl } from "store/slices/auth/selectors";

import PATHS from "constants/path.constants";
import {
	GAME_TYPE,
	GAME_LABEL_TYPE,
	GAME_TYPE_MAPPER,
	GAME_TYPE_ICON_MAPPER,
} from "constants/game.constants";
import { redirectToPage } from "utils/navigate";
import { buildPathToStaticFolderOfCDN, isMobile, isRacingGame, isRTL, mergeClassNames } from "utils/common";
import { binaryToFlags } from "utils/binaryCalculations";
import useGlobalVariables from "hooks/useGlobalVariables";
import GameCountDown from "./gameCountDown";
import sessionType from "types/session.type";
import GameType from "types/game.type";
import useAppSelector from "hooks/store/useAppSelector";

/* Single Game Component in topBar */
const Game = ({
	game,
	isFromModal = false,
	onClickCallback = null,
	showCountdown = true,
	selectedGame,
	setSelectedGame
}) => {
	const games = useAppSelector(selectSessionGames);
	const currentTime = useAppSelector(selectCurrentTime);

	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const navigate = useNavigate();
	const path = useCurrentPath();

	const isRTLLanguage = useAppSelector(selectIsRtl);

	/** Fires on match click
	 * @function
	 * @param {number} type - type of clicked game
	 * @memberOf Game
	 */
	const handleClick = (type) => {
		if (selectedGame !== type) {
			setSelectedGame(type);
			if (path !== PATHS.RESULTS && path !== PATHS.STATISTICS && path !== PATHS.RULES) {
				redirectToPage(navigate, PATHS.HOME);
			}
			if (typeof onClickCallback === "function") {
				onClickCallback(type);
			}
		}
	};

	const renderGameName = () => {
		return <span className="vs--topBar-game-name vs--title-gray-50 vs--font-normal vs--font-regular">{GAME_TYPE_MAPPER[game.type] ? t(`common.${GAME_TYPE_MAPPER[game.type]}`) : null}</span>;
	};

	return (
		<div className={mergeClassNames("vs--topBar-game", selectedGame === game.type && "vs--topBar-game-active")} onClick={() => handleClick(game.type)} data-unique={game.type}>
			<div className="vs--topBar-game-content vs--flex vs--flex-row vs--align-center">
				<div className="vs--topBar-game-icon-wrapper">
					<i className={mergeClassNames(GAME_TYPE_ICON_MAPPER[game.type] && GAME_TYPE_ICON_MAPPER[game.type], "vs--topBar-game-icon vs--font-bigest")} />
				</div>
				{isFromModal ? null : renderGameName()}
				{showCountdown && games.length > 1 && game.nextEventStartTime !== null ? <GameCountDown game={game} currentTime={currentTime} isFromModal={isFromModal} /> : null}
			</div>
			{
				isFromModal
					? renderGameName()
					: null
			}
			{
				binaryToFlags(
					Object.values(GAME_LABEL_TYPE),
					game.gameLabel
				).includes(GAME_LABEL_TYPE.NEW)
					? (
						<div className="vs--topBar-game-new-badge">
							<svg xmlns="http://www.w3.org/2000/svg" width="37.037" height="15.997" viewBox="0 0 37.037 15.997">
								<g id="Label" transform="translate(-218.563 -1478.96)">
									<g id="Group_34705" data-name="Group 34705">
										<path id="Union_68" data-name="Union 68" d="M21131.355,10675.614c-.34,0-.516-.7-.293-1.147l2.215-4.5a2.479,2.479,0,0,0,0-2.008l-2.215-4.506c-.223-.445-.047-1.147.293-1.147h34.822v-1.344H21168v13.266c0,.771-.35,1.387-.781,1.387Z" transform="translate(-20912.4 -9180.657)" fill="#c00"/>
										<path id="Path_49205" data-name="Path 49205" d="M1628.576,1480.3c0-.742-.822-1.343-1.836-1.343h-.77v2.687h.77C1627.754,1481.647,1628.576,1481.045,1628.576,1480.3Z" transform="translate(-1372.976 0)" fill="#a37500"/>
									</g>
								</g>
							</svg>
							<span>NEW</span>
						</div>
					)
					: null
			}
		</div>
	);
};

/** Game propTypes
 * PropTypes
 */
Game.propTypes = {
	/** Game object for the component */
	game: GameType,
	/** React property, is component mount from modal */
	isFromModal: PropTypes.bool,
	/** React property, when it's mount from modal */
	onClickCallback: PropTypes.func,
	/** React property, whether show or hide game countdown */
	showCountdown: PropTypes.bool,
	/** React property, given selected game from parent component or currentGameType by default */
	selectedGame: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** React property, given callback from parent component or setCurrentGame by default */
	setSelectedGame: PropTypes.func
};


export default Game;
