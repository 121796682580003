import { createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "translations/config";

import bonusService from "services/api/bonus";

import { sliceName } from "./initialState";

import { setUseBonusAction } from "./actions";
import {
	setStakeAction,
	setStakeModeAction
} from "store/slices/betslip/actions";
import {
	selectSessionBonuses,
	selectIsQuickBetEnabled,
	selectQuickBetAmount,
	selectSessionGames,
	selectSessionCurrency
} from "store/slices/auth/selectors";
import {
	selectBets
} from "store/slices/betslip/selectors";
import { saveSettingsThunk } from "../auth/thunks";

import { binaryToFlags } from "utils/binaryCalculations";
import { BETSLIP_STAKE_MODES } from "constants/betslip.constants";
import { BONUS_TYPE, BONUS_STATES } from "constants/bonus.constants";
import { GAME_TYPE_MAPPER } from "constants/game.constants";
import ToastManager from "helpers/toastManager";

export const bonusHistoryThunk = createAsyncThunk(`${sliceName}/bonusHistory`, async (filters, { signal }) => {
	const status = BONUS_STATES.ALL === filters.status ? "" : filters.status;
	const response = await bonusService.history({ ...filters, status }, signal);

	return response.data.value;
});

export const addBonusHistoryThunk = createAsyncThunk(`${sliceName}/addHistory`, async (filters, { signal }) => {
	const status = BONUS_STATES.ALL === filters.status ? "" : filters.status;
	const response = await bonusService.history({ ...filters, status }, signal);

	return response.data.value;
});

export const useBonusThunk = createAsyncThunk(`${sliceName}/useBonus`, async (enabled, { signal, dispatch, getState }) => {
	const state = getState();
	const bonuses = selectSessionBonuses(state);
	const games = selectSessionGames(state);
	const currency = selectSessionCurrency(state);

	const bets = selectBets(state);
	const isQuickBetEnabled = selectIsQuickBetEnabled(state);
	const quickBetAmount = selectQuickBetAmount(state);

	dispatch(setUseBonusAction(enabled));

	if (enabled) {
		const availableGamesTypes = games.map((game) => game.type);
		const singleMin = currency?.singleMin ?? 1;

		const difference = bonuses[0].amount - singleMin;
		const totalStack = difference < singleMin ? bonuses[0].amount : singleMin;

		let bonusType = 0,
			bonusGameType = 0,
			bonusAmount = 0,
			bonusRoundCount = 0;

		if (bonuses.length > 0) {
			bonusType = bonuses[0].bonusType;
			bonusGameType = bonuses[0].gameType;
			bonusAmount = bonusType === BONUS_TYPE.FREEAMOUNT ? (isQuickBetEnabled ? totalStack : "") : bonuses[0].amount;
			bonusRoundCount = bonuses[0].roundCount;
		}

		const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonusGameType);

		if (bets.some((bet) => !bonusGamesTypes.includes(bet.gameType))) {
			const notIncludedGamesText = availableGamesTypes.reduce((acc, gameType) => {
				if (!bonusGamesTypes.includes(gameType)) {
					acc += `${acc.length > 0 ? ", " : ""}${i18n.t(`common.${GAME_TYPE_MAPPER[gameType]}`)}`;
				}

				return acc;
			}, "");

			ToastManager.instance.error(i18n.t("errors.message.bonusInvalidGames").replace("%%notIncludedGames%%", notIncludedGamesText));
			return;
		}

		if (bonusType === BONUS_TYPE.FREEBET && bets.length > bonusRoundCount) {
			ToastManager.instance.error(i18n.t("errors.message.InappropriateQuantity"));
			dispatch(setUseBonusAction(false));
			return;
		}

		if (isQuickBetEnabled && bonusAmount !== Number(quickBetAmount)) {
			dispatch(saveSettingsThunk({ quickBetAmount: bonusAmount }));
		}

		dispatch(setStakeAction(bonusAmount));
		dispatch(setStakeModeAction(BETSLIP_STAKE_MODES.PER_BET));
	}

	return response.data.value;
});