export const PERIOD = {
	MATCH: 0,
	FIRSTHALF: 1,
	SECONDHALF: 2,
	NONE: 3,
	OVERTIME: 4,
	PENALTY_SHOOTOUT: 5
};

export const ODD_FORMATS = {
	DECIMAL: 1,
	FRACTION: 2,
	AMERICAN: 4
};

export const RACING_MARKETS_TABS = {
	MAIN: "main",
	FORECAST: "forecast",
	TRICAST: "tricast",
	ODD_EVEN: "oddEven",
	TOTAL: "total",
	EXTRA: "extra"
};

export const RACING_MARKETS_GROUPS = {
	WINNER: "Winner",
	PLACE: "Place",
	SHOW: "Show",
	PAIR: "Pair",
	PAIR_ANY_ORDER: "PairAnyOrder",
	TRIPLE: "Triple",
	TRIPLE_ANY_ORDER: "TripleAnyOrder",
	RACE_EVEN_ODD: "RaceEvenOdd",
	WINNER_NUMBER: "WinnerNumber",
	TOTAL_123_PLACED: "Total123Placed",
	WILL_NOT_FINISHERS: "WillNonFinishers",
	TOTAL_NOT_FINISHERS: "TotalNonFinishers"
};

export const ODD_FORMATS_TRANSLATION_KEYS = {
	[ODD_FORMATS.DECIMAL]: "common.decimal",
	[ODD_FORMATS.FRACTION]: "common.fraction",
	[ODD_FORMATS.AMERICAN]: "common.american"
};

export const RACING_PENDING_ODDS_CELLS_BY_TAB = {
	[RACING_MARKETS_TABS.FORECAST]: {
		[RACING_MARKETS_GROUPS.PAIR]: [1, 2],
		[RACING_MARKETS_GROUPS.PAIR_ANY_ORDER]: [0]
	},
	[RACING_MARKETS_TABS.TRICAST]: {
		[RACING_MARKETS_GROUPS.TRIPLE]: [1, 2, 3],
		[RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER]: [0]
	}
};

export const CORRECT_SCORE_TABS = {
	0: "common.home",
	1: "common.draw",
	2: "common.away"
};

export const PENALTY_CORRECT_SCORE_TABS = {
	0: "common.home",
	1: "common.away"
};

export const PENALTY_SHOOTOUT_MARKETS_TABS = {
	ALL: "all",
	MAIN: "main",
	CORRECT_SCORE: "correctScore",
	TOTAL: "total",
	HANDICAP: "handicap"
};

export const PENALTY_SHOOTOUT_MARKETS_GROUPS = {
	RESULT: "Winner2Way",
	BOTH_TEAMS_TO_SCORE: "BothTeamsToScore",
	HOME_GOAL_MISS: "HomeGoalMiss",
	AWAY_GOAL_MISS: "AwayGoalMiss",
	HOME_SHOT_OUTCOME: "HomePenaltyShot",
	AWAY_SHOT_OUTCOME: "AwayPenaltyShot",
	SUDDEN_DEATH_OUTCOME: "MatchEndsInOvertime",
	SUDDEN_DEATH_RESULT: "MethodOfVictoryOvertime",
	CORRECT_SCORE: "CorrectScore",
	CORRECT_SCORE_PER_SHOT: "CorrectScorePerShot",
	HANDICAP: "Handicap",
	ASIAN_HANDICAP: "AsianHandicap",
	TOTAL_GOALS: "TotalGoals",
	TOTAL_PENALTIES: "TotalRealizePenalty",
	TOTAL_MISS: "TotalMissed",
	TOTAL_GOALS_ASIAN: "AsianTotal",
	MATCH_GOALS_COUNT: "MatchGoalsCount"
};

export const PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS = {
	PENALTY_SHOT_OUTCOME: "PenaltyShotOutcome",
	[PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME]: `${PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME}_MissCase`,
	[PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME]: `${PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME}_MissCase`
};

export const PENALTY_MISS_CASE_MARKETS = {
	OUT_OF_GOALS: "OutOfGoals",
	GOAL_SAVES: "GoalSaves",
	GOAL_WOODWORKS: "GoalWoodworks"
};

export const QUICK_BET_PROCESS_STATES = {
	PENDING: "pending",
	SUCCESSFUL: "successful",
	REJECTED: "rejected"
};