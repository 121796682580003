/**
 * Check argument is string
 * @function
 * @param {any} arg possibly string
 * @returns {boolean}
 */
const isString = (arg) => {
	return typeof arg === "string";
};

export default isString;
