import { useEffect, useState } from "react";

/**
 * IntersectionObserver API
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
const useIntersectionObserver = ({ targetRef, root = null, rootMargin = "0px", threshold = 1.0 }) => {
	const [intersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				setIntersecting(entries[0].isIntersecting);
			},
			{
				root,
				rootMargin,
				threshold
			}
		);

		observer.observe(targetRef.current);

		return () => {
			observer.disconnect();
		};
	}, []);

	return intersecting;
};

export default useIntersectionObserver;
